import React, { Component } from "react"
import axios from "axios"

const GOOGLE_FORM_ORG_NAME = "entry.18093427"
const GOOGLE_FORM_CONTACT_NAME = "entry.2129038797"
const GOOGLE_FORM_CONTACT_INFO = "entry.2111087123"
const GOOGLE_FORM_HELP_HOW = "entry.1597067986"

const CORS_PROXY = "https://cors-anywhere.herokuapp.com/"
const GOOGLE_FORM_ACTION =
  "https://docs.google.com/forms/u/0/d/e/1FAIpQLSd-yYEPLLneukir3AMIeJPcpmg0KjcBiPWCJnQXEa5X7MQWpw/formResponse"

class ServicesRequest extends Component {
  constructor(props) {
    super(props)
    this.state = {
      org_name: "",
      contact_name: "",
      contact_info: "",
      help_how: "",
      showForm: true,
      sendingMessage: false,
      messageSent: false,
      messageError: false,
    }
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleSubmit = event => {
    event.preventDefault()
    this.setState({
      sendingMessage: true,
    })
    this.sendMessage()
  }

  handleFormToggle = () => {
    this.setState(prevState => {
      const { showForm } = prevState
      return {
        showForm: !showForm,
      }
    })
  }

  handleReturnButton = () => {
    this.setState({
      showForm: false,
      messageSent: false,
      messageError: false,
    })
  }

  sendMessage = () => {
    const formData = new FormData()
    formData.append(GOOGLE_FORM_ORG_NAME, this.state.org_name)
    formData.append(GOOGLE_FORM_CONTACT_NAME, this.state.contact_name)
    formData.append(GOOGLE_FORM_CONTACT_INFO, this.state.contact_info)
    formData.append(GOOGLE_FORM_HELP_HOW, this.state.help_how)

    axios
      .post(CORS_PROXY + GOOGLE_FORM_ACTION, formData)
      .then(() => {
        this.setState({
          messageSent: true,
          sendingMessage: false,
          org_name: "",
          contact_name: "",
          contact_info: "",
          help_how: "",
        })
      })
      .catch(() => {
        this.setState({
          messageError: true,
          sendingMessage: false,
        })
      })
  }

  returnButton = () => (
    <button
      id="return-button"
      className="btn btn-default btn-xs"
      onClick={this.handleReturnButton}
    >
      Return
    </button>
  )

  render() {
    if (this.state.sendingMessage) {
      return <div className="container">Sending...</div>
    }

    if (this.state.messageSent) {
      return (
        <React.Fragment>
          <div className="container msg-container">
            <div className="success-message">
              <p>
                <b>Connect form submitted!</b>{" "}
              </p>{" "}
              <p>We will respond as soon as possible with next steps.</p>
            </div>
            {this.returnButton()}
          </div>
        </React.Fragment>
      )
    }

    if (this.state.messageError) {
      return (
        <React.Fragment>
          <div className="container msg-container">
            <div className="error-message">
              <p>
                <b>Sorry, your message was not sent</b>{" "}
              </p>{" "}
              <p>
                Please contact Janine Stammler at jstammler@ea-mn.org or
                651-379-2675.
              </p>
            </div>
            {this.returnButton()}
          </div>
        </React.Fragment>
      )
    }

    if (this.state.showForm === false) {
      return (
        <div className="container">
          <button
            id="contact-button"
            className="btn btn-sm"
            onClick={this.handleFormToggle}
          >
            Click to Start Connect Form
          </button>
        </div>
      )
    }

    return (
      <React.Fragment>
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-8 offset-md-2">
              <div className="form-container">
                {/*<h2 className="formh2">Request Form</h2>*/}
                {/*<hr />*/}
                <form onSubmit={this.handleSubmit}>
                  <div className="form-group row">
                    <div className="col-sm-12">
                      {/* <label htmlFor="org_name" className="col-form-label">
                        Organization Name
                      </label> */}
                      <input
                        type="org_name"
                        name="org_name"
                        id="org_name"
                        className="form-control"
                        placeholder="Organization Name"
                        value={this.state.org_name}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-12">
                      {/* <label htmlFor="contact_name" className="col-form-label">
                        Contact Person Name:
                      </label> */}
                      <input
                        type="contact_name"
                        name="contact_name"
                        id="contact_name"
                        className="form-control"
                        placeholder="Contact Person Name"
                        value={this.state.contact_name}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-12">
                      {/* <label htmlFor="contact_phone" className="col-form-label">
                        Contact Person Phone:
                      </label> */}
                      <input
                        type="contact_info"
                        name="contact_info"
                        id="contact_info"
                        className="form-control"
                        placeholder="Preferred Contact Information"
                        value={this.state.contact_info}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-12">
                      {/* <label htmlFor="services_desc" className="col-form-label">
                        Please provide brief description of services requested?
                      </label> */}
                      <textarea
                        id="help_how"
                        name="help_how"
                        className="form-control"
                        placeholder="Please share how we can help"
                        required
                        value={this.state.help_how}
                        onChange={this.handleChange}
                        rows="4"
                      />
                    </div>
                  </div>
                  <div>
                    <button
                      type="button"
                      id="cancel-button"
                      className="btn btn-default btn-sm btn-action cancelbutton"
                      onClick={this.handleFormToggle}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-lg btn-default btn-action submitbutton"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default ServicesRequest
