import React from "react"
import Layout from "../components/layout"
import PageHeader from "../components/pageheader"
import ServicesRequest from "../components/request"

const LetsConnect = () => {
  const subTitle = `So we can help serve you better, please complete the form below. We will respond as soon as possible, usually within a few business days.`
  const headingTitle = "Let's Connect"

  return (
    <Layout>
      <PageHeader
        subTitle="Equity Alliance MN"
        headingTitle={headingTitle}
        theme="about"
      />
      <div className="container">
        <p className="homep mt-4">
          Equity Alliance MN is here to support you. Let us know what information you are looking for and we will reach out!
        </p>
        {/*<hr />*/}
      </div>
      {/*<Contact />*/}
      <ServicesRequest />
    </Layout>
  )
}

export default LetsConnect
